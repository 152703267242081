<template>
  <ion-page style="background: none transparent;">
    <Header type="0" title="扫描二维码"></Header>
    <ion-content no-scroll class="qrscanner hide content">
      <div class='qrscanner-area'></div>
      <div class='through-line'></div>
    </ion-content>
  </ion-page>
</template>

<script>
import Header from "@/components/Header.vue";
let frompath;
export default {
  data() {
    return{
      isShow: false
    }
  },
  components: {
    Header,
  },
  beforeRouteEnter:function(to,from,next){
    frompath = from.fullPath
    next()
  },
  beforeCreate() {
    //document.getElementById("app").style.backgroundColor = "transparent";
    //document.querySelector("body").style.backgroundColor = "transparent";
  },
  ionViewDidEnter() {
    console.log(frompath,123)
    this.openQrScanner();
  },
  methods:{
    openQrScanner(){
      try {
        QRScanner.show((status) => {
          alert("[Scan.vue onScan] 显示扫描" + JSON.stringify(status));
        });
        QRScanner.scan((err, contents) => {
          if (err) {
            alert(JSON.stringify(e));
          } else {
            this.$router.replace({
              path:frompath,
              params:{contents:JSON.stringify(contents)}
            })

          }
        });
      } catch (e) {
        console.log("[Scan.vue：onScan] " + JSON.stringify(e));
      }
    },
    openLight(){
      try {
        if (!this.light) {
          QRScanner.enableLight((err, status) => {
            err && console.log("[Scan.vue] 打开手电筒状态错误：" + JSON.stringify(err));
            console.log("[Scan.vue] 打开手电筒状态：" + JSON.stringify(status));
          });
        } else {
          QRScanner.disableLight((err, status) => {
            err && console.log("[Scan.vue] 关闭手电筒状态错误：" + JSON.stringify(err));
            console.log("[Scan.vue] 关闭手电筒状态：" + JSON.stringify(status));
          });
        }
      } catch (e) {
        console.log("[Scan.vue] " + JSON.stringify(e));
        return;
      }
      this.light = !this.light;
    },
    distroyScanner(){
      //恢复之前的背景
      try {
        QRScanner.hide((status) => {
          console.log("[Scan.vue] 关闭扫描" + JSON.stringify(status));
        });
        QRScanner.destroy(function (status) {
          console.log("[Scan.vue] 销毁扫码" + JSON.stringify(status));
        });
      } catch (e) {
        console.log("[Scan.vue]" + JSON.stringify(e));
      }
    }
  },
  beforeUnmount() {
    console.log(123)
    this.distroyScanner();
  },
}
</script>

<style scoped lang="scss">
html, body, ion-app, ion-page,.app-root,ion-nav, .nav-decor, ion-content,.viewscan, .fixed-content,.scroll-content {
  background-color: transparent !important;
  background: transparent none!important;
}
Header, header{
  height: 29px;
}
ion-content {
  --background: none transparent;
}
[app-viewport],
[overlay-portal],
[nav-viewport],
[tab-portal],
.nav-decor {
  display: none !important;
  background: none transparent !important;
}

.qrscanner {
    background: transparent;
    &-area {
      width: 100%;
      height: 86%;
      background: url(../../assets/images/scanner.svg) no-repeat center center;
      background-size: contain;
    }
  }
  .through-line {
    left: 50%;
   transform: translateX(-50%);
    width: 50%;
    height: 2px;
    background: red;
    position: absolute;
    animation: myfirst 2s linear infinite alternate;
  }
  @keyframes myfirst {
    0% {
      background: red;
      top: 40%;
    }
    25% {
      background: yellow;
      top: 45%;
    }
    50% {
      background: blue;
      top: 50%;
    }
    75% {
      background: green;
      top: 55%;
    }
    100% {
      background: red;
      top: 60%;
    }
  }
  .button-bottom {
    width: 128px;
    position: absolute;
    left: 50%;
    bottom: 80px;
    margin-left: -64px;
    .icon-camera {
      float: left;
    }
  }
#close-scanner-button {
  display: none;
}

html.qr-scanner-open {

  ion-app,
  ion-content {
    height: 70px !important;
  }

  ion-content {
    --overflow: hidden !important;
  }

  ion-header,
  ion-footer,
  ion-content > *:not(#close-scanner-button) {
    display: none;
  }

  #close-scanner-button {
    display: block;
  }
}
.hide {
  --background: none transparent;
}
</style>